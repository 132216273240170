<template>
    <div v-if="modal" class="modal archive-offer" :class="{ active: modal }">
        <div class="modal-card">
            <p class="title">{{$t('workProviderOffer.shared.card.delete.confirmation')}}</p>

            <div class="modal-body">
                <div class="input-container row">
                    <div class="row">
                        <p>{{$t('workProviderOffer.shared.card.delete.offer_filled')}}</p>

                        <div class="button-group collapsed nomargin-right">
                            <button :class="['cta cta-option', { active: data.offer_filled }]" @click="data.offer_filled = true">{{$t('language.yes')}}</button>
                            <button :class="['cta cta-option', { active: !data.offer_filled }]" @click="data.offer_filled = false">{{$t('language.no')}}</button>
                        </div>
                    </div>
                </div>

                <div v-if="data.offer_filled" class="input-container row">
                    <div class="row">
                        <p>{{$t('workProviderOffer.shared.card.delete.offer_filled_by_likab_candidate')}}</p>

                        <div class="button-group collapsed nomargin-right">
                            <button :class="['cta cta-option', { active: data.offer_filled_by_likab_candidate }]" @click="data.offer_filled_by_likab_candidate = true">{{$t('language.yes')}}</button>
                            <button :class="['cta cta-option', { active: !data.offer_filled_by_likab_candidate }]" @click="data.offer_filled_by_likab_candidate = false">{{$t('language.no')}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta-container">
                <div class="cta cta-outline dark cta-slim" @click="close()">{{$t('language.words.back')}}</div>
                <div class="cta cta-error dark cta-slim" @click="delete_offer()" :class="{ loading: loading }">{{$t('workProviderOffer.shared.card.delete.archive')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArchiveOfferModal',

    props: {
        id: {
            type: String,
            default: ''
        },

        show: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        show: function(val) {
            const self = this;
            self.modal = val;
        },

        modal: function() {
            const self = this;
            if(self.modal) { 
                self.$utils._open_modal(); 
            }
            else { self.$utils._close_modal(); }
        },

        'data.offer_filled': function() {
            const self = this;
            if(!self.data.offer_filled) { self.data.offer_filled_by_likab_candidate = false; }
        },
    },
    
    data() {
        return {
            modal: false,
            loaded: false,
            loading: false,

            data: {
                offer_filled: false,
                offer_filled_by_likab_candidate: false
            },
        }
    },

    computed: {
        app_version: function() {
            return `v${process.env.VUE_APP_VERSION}`
        }
    },
    
    methods: {
        delete_offer: function() {
            const self = this;

            if(self.id) {
                self.loading = true;

                const path_params = {
                    offer_filled: self.data.offer_filled ? 'yes' : 'no',
                    offer_filled_by_likab_candidate: self.data.offer_filled_by_likab_candidate ? 'yes' : 'no',
                }

                self.$axios
                    .delete(`/work-provider/offer/paid/${self.id}/${path_params.offer_filled}/${path_params.offer_filled_by_likab_candidate}`)
                    .then(response => {
                        if(response.status == 200){
                            self.$toasted.success(
                                self.$t('notifications.workProviderOfferArchivedSuccess'),
                                { icon: 'circle-check' }
                            );

                            self.$utils._navigate_to_name('work-provider-offers');
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }

                        self.loading = false;
                    });
            }
        },

        close: function() {
            const self = this;

            self.modal = false;
            self.$emit('close');
        },
    }
}
</script>