<template>
    <div>
        <div :class="['card card-stats']">
            <div class="card-body">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.stats.card.title')}}</p>
                </div>

                <table class="nomargin-bottom stats">
                    <tr>
                        <th>{{$t('table.workProviderOffer.stats.th.stage')}}</th>
                        <th>{{$t('table.workProviderOffer.stats.th.candidates')}}</th>
                        <th>{{$t('table.workProviderOffer.stats.th.percentage')}}</th>
                    </tr>

                    <tr class="no-hover" v-for="(stat, key) in stats.stages" :key="key">
                        <td class="bold no-wrap tt" :content="$t(`tooltips.matches.${key}.th`)" v-tippy>{{$t(`matches.stages.${key}`)}}<TippyIndicator /></td>
                        <td>{{stat}}</td>
                        <td class="tt bold small" v-if="key === 'new'">N/A</td>
                        <td class="tt bold" v-else :content="$t(`tooltips.matches.${key}.percentage`)" v-tippy>{{stats.percentages[key]}}% <TippyIndicator /></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import TippyIndicator from '../../../Utils/TippyIndicator.vue'

export default {
    name: 'WorkProviderOfferStatsCard',

    components: {
        TippyIndicator
    },

    props: {
        stats: {
            type: Object,
            default() {
                return {}
            }
        }
    }
}
</script>
