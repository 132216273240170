<template>
    <div>
        <div class="card card-split">
            <div class="card-heading">
                <p class="title">{{$t('workProviderOffer.shared.card.split.information.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <OfferDescriptionComponent :description="offer.description" :readonly="true" />
                </div>
            </div>
        </div>

        <div class="card card-split">
            <div class="card-heading with-subtitle nomargin-bottom">
                <p class="title">{{$t('workProviderOffer.shared.card.split.classification.title')}}</p>
                <p class="subtitle">{{$t('workProviderOffer.shared.card.split.classification.subtitle')}}</p>            
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <ClassificationComponent    :classification="offer.classification"
                                                :salaries="offer.salaries"
                                                :readonly="true"
                                                client="work-provider"  />
                </div>
            </div>
        </div>

        <div class="card card-split">
            <div class="card-heading">
                <p class="title">{{$t('workProviderOffer.shared.card.split.schedule.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <div class="question schedule-type column">   
                        <div class="label-container">
                            <p>{{$t('workProviderOffer.shared.card.schedule_type.title')}}</p>
                        </div>

                        <input :class="['input input-text read-only']" type="text" :value="$t(`workProviderOffer.shared.card.schedule_type.choices.${offer.schedule_type}`)" />
                    </div>

                    <WorkDaysComponent  v-if="offer.workDays"
                                        :workDays="offer.workDays"
                                        :workTimes="offer.workTimes"
                                        
                                        :readonly="true"
                                        client="workProvider" />

                    <WorkTimesComponent  v-if="offer.workTimes"
                                        :workDays="offer.workDays"
                                        :workTimes="offer.workTimes"
                                        :hoursPerWeek="offer.hoursPerWeek"
                                        :schedule_type="offer.schedule_type"
                                        :hours_per_week_persons_choice="offer.hours_per_week_persons_choice"
                                        
                                        :readonly="true"
                                        client="workProvider" />
                    
                    <OfferSchedulePrecisionComponent :schedule_precision="offer.schedule_precision" :readonly="true" />
                </div>
            </div>
        </div>

        <div class="card card-split">
            <div class="card-heading">
                <p class="title">{{$t('workProviderOffer.shared.card.split.compensation.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <SalariesComponent  :salaries="offer.salaries"
                                        :readonly="true"/>
                </div>
            </div>
        </div>

        <div class="card card-split">
            <div class="card-heading">
                <p class="title">{{$t('workProviderOffer.shared.card.split.benefits.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <BenefitsComponent  :benefits="offer.benefits" :readonly="true" />
                </div>
            </div>
        </div>

        <div class="card card-split">
            <div class="card-heading">
                <p class="title">{{$t('workProviderOffer.shared.card.split.location.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <!-- <div class="question work-location column">
                        <div class="label-container">
                            <p>{{$t('workProviderOffer.shared.card.workLocation.title')}}</p>
                        </div>

                        <div class="checkbox-container">
                            <div class="checkbox-with-label read-only">
                                <input type="checkbox" :checked="offer.workLocation === 'regular' && !offer.hybrid.hybrid">
                                <p class="label">{{$t('regular')}}</p>
                            </div>
                            <div class="checkbox-with-label read-only">
                                <input type="checkbox" :checked="offer.workLocation === 'remote' && !offer.hybrid.hybrid">
                                <p class="label">{{$t('remote')}}</p>
                            </div>
                            <div class="checkbox-with-label read-only">
                                <input type="checkbox" :checked="offer.workLocation === 'regular' && offer.hybrid.hybrid">
                                <p class="label">{{$t('hybride')}}</p>
                            </div>
                            <div class="checkbox-with-label read-only">
                                <input type="checkbox" :checked="offer.workLocation === 'both'">
                                <p class="label">{{$t('both')}}</p>
                            </div>
                        </div>
                    </div>
                    
                    <HybridComponent    v-if="offer.hybrid.hybrid"
                                        :hybrid="offer.hybrid"
                                        :readonly="true" /> -->

                    <div v-if="locations && (offer.workLocation === 'regular' || offer.workLocation === 'both')" class="question locations column">
                        <div class="label-container">
                            <p v-if="offer.hybrid.hybrid">{{$t('workProviderOffer.shared.card.locations.title.hybrid')}}</p>
                            <p v-else>{{$t('workProviderOffer.shared.card.locations.title.default')}}</p>                        
                        </div>

                        <div :class="['location-container read-only']">
                            <div :class="['location active']"  v-for="location in locations" :key="location._id">
                                <div class="text">
                                    <span>
                                        <p class="name">{{location.name}}</p>
                                    </span>

                                    <p class="address">{{location.formattedAddress}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="card card-split">
            <div class="card-heading">
                <p class="title">{{$t('workProviderOffer.shared.card.split.dates.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <div class="question-wrapper">
                        <div class="question start-date">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.startDate.title')}}</p>
                            </div>

                            <VueDatePicker  v-model="offer.startDate" 
                                            class="read-only"
                                            :locale="{ lang: currentLanguage }"
                                            color="#303030" />
                        </div>

                        <div v-if="offer.endDate" class="question end-date">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.endDate.title')}}</p>
                            </div>

                            <VueDatePicker  v-model="offer.endDate" 
                                            class="read-only"
                                            :min-date="offer.startDate"
                                            :locale="{ lang: currentLanguage }"
                                            color="#303030" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WorkDaysComponent from '../../../../Form/WorkDaysComponent.vue';
import WorkTimesComponent from '../../../../Form/WorkTimesComponent.vue';
import ClassificationComponent from '../../../../Form/ClassificationComponent.vue';
import SalariesComponent from '../../../../Form/SalariesComponent.vue';
import BenefitsComponent from '../../../../Form/BenefitsComponent.vue';
import OfferDescriptionComponent from '../../../../Form/OfferDescriptionComponent.vue';
import OfferSchedulePrecisionComponent from '../../../../Form/OfferSchedulePrecisionComponent.vue';

export default {
    name: 'WorkProviderPaidOfferCard',

    components: {
        WorkDaysComponent,
        WorkTimesComponent,
        ClassificationComponent,
        SalariesComponent,
        BenefitsComponent,
        OfferDescriptionComponent,
        OfferSchedulePrecisionComponent
    },

    props: {
        offer: {
            type: Object,
            default() {
                return {
                    workDays: [],
                    workTimes: []
                }
            }
        },
    },

    data() {
        return {
            locations: null
        }
    },

    computed: {
        ...mapGetters([
            'currentLanguage'
        ])
    },

    methods: {
        getLocations: function() {
            const self = this;
            
            self.$axios
                .get(`/locations/${self.offer.locations.join('&')}`)
                .then(response => {
                    if(response.status == 200) {
                        self.locations = response.data;
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        }
    },

    mounted() {
        const self = this;

        if(self.offer && self.offer.locations.length !== 0)
            self.getLocations()
    }
}
</script>

