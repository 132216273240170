<template>
    <layout :classes="classes" 
            :routeName="routeName" 
            :navigation="navigation" 
            :sidebar="sidebar" :scope="scope"
            :pace="{ activate: true, loaded: loaded }">
        
        <div>
            <div id="sticky-heading-container" class="heading-container sticky">
                <div class="back-title-container">
                    <BackButton v-if="state === 'consult'" route="work-provider-offers" />
                    <h1 class="title" v-if="state === 'consult'">{{titleKey}}</h1>
                    <h1 class="title" v-else>{{$t(titleKey)}}</h1>
                </div>
                
                <div v-if="state === 'consult'" class="cta-container">
                    <div class="cta cta-primary cta-slim" @click="modify_offer()">{{$t('pages.workProviderOffer.ctas.modify')}}</div>
                    <div class="cta cta-error cta-slim" @click="delete_modal.modal = true">{{$t('pages.workProviderOffer.ctas.archive')}}</div>
                </div>

                <div v-else class="cta-container">
                    <button class="cta cta-primary cta-slim" :class="{ loading: update_offer.loading }" @click="update_offer.loading = true">{{$t('cards.generic.submit.label')}}</button>
                    <button class="cta cta-neutral cta-slim" @click="set_state_to_consult()">{{$t('language.cancel')}}</button>
                </div>
            </div>
            
            <div id="with-sticky-header" class="row with-sticky-header">
                <div v-if="loaded" class="left">
                    <WorkProviderPaidOfferCard          v-if="state === 'consult'"  
                                                        :offer="offer" />
                    
                    <UpdateWorkProviderPaidOfferCard    v-else   
                                                        :trigger_update_offer="update_offer.loading"                   
                                                        :offer="offer"
                                                        @update_offer_triggered="update_offer_triggered($event)" />
                </div>

                <div v-if="loaded" :class="['right', {fixed: state === 'consult'}]">
                    <WorkProviderOfferStatsCard         v-if="state === 'consult'" 
                                                        :stats="stats" />
                    
                    <!-- <WorkProviderOfferCandidatesCard    v-if="state === 'consult'"
                                                        :id="id"
                                                        :currentProfileType="currentProfileType"
                                                        :count="count"
                                                        :disclaimer="showDisclaimer" /> -->

                    <div v-if="state === 'modify'">
                        <button v-if="valid_recommendations !== null && !valid_recommendations" :class="['cta cta-primary cta-slim cta-unlock-recommendations', { 'nomargin-bottom': expired }]" @click="unlock_modal.modal = true">{{$t(expired ? 'recommendations.unlock.expired': 'recommendations.unlock.title')}}</button>
                        <span class="expiration-since" v-if="valid_recommendations !== null && !valid_recommendations && expired" v-html="$t('recommendations.unlock.expired_since', { date: expiration_date })" />

                        <CompetitionCard :classification="offer.classification" :valid_recommendations="valid_recommendations" :id="offer._id" type="published" />
                        <DemandCard :classification="offer.classification" :valid_recommendations="valid_recommendations" />
                        <BenefitsCard :classification="offer.classification" :valid_recommendations="valid_recommendations" :id="offer._id" type="published" />
                        <SalaryCard :classification="offer.classification" :valid_recommendations="valid_recommendations" :id="offer._id" type="published" />
                        
                        <span class="expiration-since expires" v-if="!expired && expiration_date" v-html="$t('recommendations.unlock.expires', { date: expiration_date })" />
                    </div>
                </div>
            </div>
        </div>
        
        <ArchiveOfferModal :show="delete_modal.modal" :id="id" @close="delete_modal.modal = false" />
        
        <UnlockAdvancedRecommendationsModal :work_provider_offer_id="id" 
                                            :show="unlock_modal.modal"
                                            @close="unlock_modal.modal = false"
                                            @success="unlock_success()"
                                            type="published" />
    </layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { bus } from '../../../../main';

import Layout from '../../../../components/Layout.vue';
import BackButton from '../../../../components/Navigation/BackButton.vue';
import WorkProviderOfferStatsCard from '../../../../components/Cards/Offers/WorkProvider/WorkProviderOfferStatsCard.vue'
//import WorkProviderOfferCandidatesCard from '../../../../components/Cards/Offers/WorkProvider/WorkProviderOfferCandidatesCard.vue'

import WorkProviderPaidOfferCard from '../../../../components/Cards/Offers/WorkProvider/Paid/WorkProviderPaidOfferCard.vue'
import UpdateWorkProviderPaidOfferCard from '../../../../components/Cards/Offers/WorkProvider/Paid/UpdateWorkProviderPaidOfferCard.vue'

import CompetitionCard from '../../../../components/Cards/Offers/WorkProvider/Recommendations/CompetitionCard.vue';
import DemandCard from '../../../../components/Cards/Offers/WorkProvider/Recommendations/DemandCard.vue';
import BenefitsCard from '../../../../components/Cards/Offers/WorkProvider/Recommendations/BenefitsCard.vue';
import SalaryCard from '../../../../components/Cards/Offers/WorkProvider/SalaryCard.vue'
import UnlockAdvancedRecommendationsModal from '../../../../components/Modals/MatchDetail/WorkProvider/UnlockAdvancedRecommendationsModal.vue';
import ArchiveOfferModal from '../../../../components/Modals/ArchiveOfferModal.vue';

export default {
    name: 'WorkProviderOffer',

    components: {
        Layout,
        BackButton,
        WorkProviderOfferStatsCard,
        //WorkProviderOfferCandidatesCard,
        WorkProviderPaidOfferCard,
        UpdateWorkProviderPaidOfferCard,
        CompetitionCard,
        DemandCard,
        BenefitsCard,
        SalaryCard,
        UnlockAdvancedRecommendationsModal,
        ArchiveOfferModal
    },

    props: {
        id: {
            type: String,
            default: null
        },

        navigation: {
            type: String,
            default: 'simple'
        },

        state: {
            type: String,
            default: 'consult'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            count: {
                interested: 0,
                unlocked: 0,
                notInterested: 0,
                notUnlocked: 0
            },

            offer: {},
            stats: {},

            routeName: 'offer',
            classes: { dashboard: true, 'work-provider-offer': true },
            
            delete_modal: {
                modal: false,
            },

            unlock_modal: {
                modal: false,
            },

            update_offer: {
                loading: false,
            },

            loaded: false,

            titleKey: '',

            valid_recommendations: true,
            expired: false,
            expiration_date: null,
        }
    },

    computed: {
        ...mapGetters([
            'currentProfileType',
        ]),

        showDisclaimer: function() {
            const self = this;

            if(self.stats.stages.interested !== self.count.interested) { return true; }
            if(self.stats.stages.unlocked !== self.count.unlocked) { return true; }
            if(self.stats.stages.notInterested !== self.count.notInterested) { return true; }
            if(self.stats.stages.notUnlocked !== self.count.notUnlocked) { return true; }

            return false;
        }
    },

    watch: {
        state: function(val) {
            const self = this;
            if(val === 'consult') {
                self.titleKey = `${self.offer._id.substring(self.offer._id.length - 4, self.offer._id.length).toUpperCase()} - ${self.offer.name}`;
            }else {
                self.titleKey = self.currentProfileType === 'paid' ? 'workProviderOffer.paid.title.update' : 'workProviderOffer.volunteer.title.update';
            }
        },
    },

    methods: {
        update_offer_triggered: function(change_state) {
            const self = this;

            self.update_offer.loading = false;
            if(change_state) { self.set_state_to_consult(); }
        },

        get_offer: function() {
            const self = this;

            self.$axios
                .get(`/work-provider/offer/${self.currentProfileType}/${self.id}`)
                .then(response => {
                    if(response.status == 200){
                        self.offer = response.data.offer;
                        self.stats = response.data.stats;
                        self.count.interested = response.data.interestedCandidatesCount;
                        self.count.unlocked = response.data.unlockedCandidatesCount;
                        self.count.notInterested = response.data.notInterestedCandidatesCount;
                        self.count.notUnlocked = response.data.notUnlockedCandidatesCount;

                        if(!self.offer.description) {
                            self.offer.description = 'N/A';
                        }

                        if(self.state === 'consult') {
                            self.titleKey = `${self.offer._id.substring(self.offer._id.length - 4, self.offer._id.length).toUpperCase()} - ${self.offer.name}`;
                        }else {
                            self.titleKey = self.currentProfileType === 'paid' ? 'workProviderOffer.paid.title.update' : 'workProviderOffer.volunteer.title.update';
                        }

                        self.$utils._initializeWorkTimes(self.offer.workTimes);
                        self.$utils._re_order_work_times(self.offer.workTimes);

                        self.$nextTick(() => {
                            self.loaded = true;
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        modify_offer: function() {
            const self = this;
            self.$utils._navigate_to_name_with_params('work-provider-offer', { id: self.offer._id, state: 'modify' });
        },

        set_state_to_consult: function() {
            const self = this;
            
            self.$utils._navigate_to_name_with_params('work-provider-offer', { id: self.offer._id, state: 'consult' });
            self.loaded = false;
            self.get_offer();
        },

        check_valid_recommendations: function() {
            const self = this;

            if(self.id) {
                const instance = self.$axios.create({
                    headers: { 'Authorization': process.env.VUE_APP_RECOMMENDATIONS_LAMBDA_API_KEY }
                })

                instance.get(`work-provider/offer/${self.id}/published/link`)
                    .then(response => {
                        if(response.status == 200){
                            const data = response.data;

                            self.valid_recommendations = data.active;
                            self.expired = data.reason && data.reason === 'expired';
                            if(data.expiration) { self.expiration_date = self.$moment(data.expiration).format('DD/MM/YYYY'); }
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                    });
            }
        },

        unlock_success: function() {
            const self = this;

            self.unlock_modal.modal = false;
            //self.check_valid_recommendations();
        }
    },

    mounted() {
        const self = this;

        if(self.id) {
            self.get_offer();
            //self.check_valid_recommendations();
        }
    }
};
</script>