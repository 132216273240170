<template>
    <div>
        <div v-if="loaded" class="animate__animated animate__fadeIn">
            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.information.title')}}</p>
                </div>
                
                <div class="card-body">
                    <div class="question-wrapper">
                        <div class="question name column">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.name.title')}}</p>
                            </div>
                            
                            <input :class="['input input-text', error('name')]" v-model="data.name" type="text">
                            <FormError :data="validation.name" />                     
                        </div>

                        <OfferDescriptionComponent :description="data.description" @update="update($event)"  />
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading with-subtitle">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.classification.title')}}</p>
                    <p class="subtitle">{{$t('workProviderOffer.shared.card.split.classification.subtitle')}}</p>            
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <ClassificationComponent    :classification="data.classification"
                                                    :salaries="data.salaries"
                                                    :validation="validation"
                                                    client="work-provider"
                                                    @update="update($event)" />
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.schedule.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <div class="question schedule-type column">   
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.schedule_type.title')}}</p>
                            </div>

                            <multiselect    v-model="data.schedule_type" 
                                            :options="schedule_type_choices"
                                            :close-on-select="true"
                                            :multiple="false"
                                            :searchable="false"
                                            :class="error('schedule_type')"
                                            :placeholder="$t('workProviderOffer.shared.card.schedule_type.placeholder')"

                                            :select-label="$t('language.multiselect.label.select')"
                                            :selected-label="$t('language.multiselect.label.selected')"
                                            :deselect-label="$t('language.multiselect.label.deselect')"
                                            :custom-label="translate_schedule_type"> 
                            </multiselect>  

                            <FormError :data="validation.schedule_type" />                
                        </div>

                        <div v-if="data.schedule_type">
                            <WorkDaysComponent  v-if="data.workDays"
                                            :workDays="data.workDays"
                                            :workTimes="data.workTimes"
                                            :validation="validation"
                                            client="workProvider"
                                            @update="update($event)" />

                            <WorkTimesComponent  v-if="data.workDays"
                                                :workDays="data.workDays"
                                                :workTimes="data.workTimes"
                                                :hoursPerWeek="data.hoursPerWeek"
                                                :schedule_type="data.schedule_type"
                                                :hours_per_week_persons_choice="data.hours_per_week_persons_choice"
                                                :validation="validation"
                                                client="workProvider"
                                                @update="update($event)" />

                            <OfferSchedulePrecisionComponent :schedule_precision="data.schedule_precision" @update="update($event)"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.compensation.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <SalariesComponent  :salaries="data.salaries"
                                            :validation="validation" />
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.benefits.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <BenefitsComponent  :benefits="data.benefits" />
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.location.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <!-- <div class="question work-location disabled column">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.workLocation.title')}}</p>
                                <p class="subtitle"><font-awesome-icon :icon="['fa', 'circle-exclamation']" class="warning" />{{$t('workProviderOffer.shared.card.workLocation.alternate.under')}}</p>
                            </div>
                            
                            <div class="checkbox-container">
                                <div class="checkbox-with-label read-only reduced-opacity">
                                    <input type="checkbox" :checked="data.workLocation === 'regular' && !data.hybrid.hybrid">
                                    <p class="label">{{$t('regular')}}</p>
                                </div>
                                <div class="checkbox-with-label read-only reduced-opacity">
                                    <input type="checkbox" :checked="data.workLocation === 'remote' && !data.hybrid.hybrid">
                                    <p class="label">{{$t('remote')}}</p>
                                </div>
                                <div class="checkbox-with-label read-only reduced-opacity">
                                    <input type="checkbox" :checked="data.workLocation === 'regular' && data.hybrid.hybrid">
                                    <p class="label">{{$t('hybride')}}</p>
                                </div>
                                <div class="checkbox-with-label read-only reduced-opacity">
                                    <input type="checkbox" :checked="data.workLocation === 'both'">
                                    <p class="label">{{$t('both')}}</p>
                                </div>
                            </div>

                            <FormError :data="validation.workLocation" />
                        </div>

                        <HybridComponent  v-if="data.hybrid.hybrid"
                                            :hybrid="data.hybrid"
                                            :validation="validation"
                                            @update="update($event)" /> -->

                        <div v-if="locations.length !== 0 && (data.workLocation === 'regular' || data.workLocation === 'both')" class="question locations column">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.locations.title.default')}}</p>
                                <p class="subtitle">{{$t('workProviderOffer.shared.card.locations.subtitle')}}</p>
                            </div>

                            <div :class="['location-container', error('locations')]">
                                <div :class="['location', { active: data.locations.includes(location._id) }]"  v-for="location in locations" :key="location._id" @click="selectLocation(location._id)">
                                    <div class="text">
                                        <span>
                                            <font-awesome-icon :class="{ active: data.locations.includes(location._id) }" :icon="data.locations.includes(location._id) ? ['fa', 'circle-check'] : ['far', 'circle']" />
                                            <p class="name">{{location.name}}</p>
                                        </span>

                                        <p class="address">{{location.formattedAddress}}</p>
                                    </div>
                                </div>
                            </div>

                            <FormError :data="validation.locations" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.dates.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <div class="question start-date">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.startDate.title')}}</p>
                                <FormError :data="validation.startDate" />
                            </div>

                            <VueDatePicker  v-model="data.startDate" 
                                            :class="error('startDate')"
                                            :min-date="new Date()"
                                            :locale="{lang: currentLanguage}"
                                            color="#303030" />
                        </div>

                        <div class="question has-end-date">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.endDate.needed')}}</p>
                            </div>
                            
                            <div class="button-group collapsed">
                                <button :class="['cta cta-option', { active: hasEndDate }]" @click="hasEndDate = true">{{$t('language.yes')}}</button>
                                <button :class="['cta cta-option', { active: !hasEndDate }]" @click="hasEndDate = false">{{$t('language.no')}}</button>
                            </div>
                        </div>

                        <div v-if="hasEndDate" class="question end-date">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.endDate.title')}}</p>
                                <FormError :data="validation.endDate" />
                            </div>

                            <VueDatePicker  v-model="data.endDate" 
                                            :class="error('endDate')"
                                            :min-date="data.startDate"
                                            :locale="{ lang: currentLanguage }"
                                            color="#303030" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormError from '../../../../../components/Utils/FormError.vue'
import WorkDaysComponent from '../../../../Form/WorkDaysComponent.vue';
import WorkTimesComponent from '../../../../Form/WorkTimesComponent.vue';
import ClassificationComponent from '../../../../Form/ClassificationComponent.vue';
import SalariesComponent from '../../../../Form/SalariesComponent.vue';
import BenefitsComponent from '../../../../Form/BenefitsComponent.vue';
import OfferDescriptionComponent from '../../../../Form/OfferDescriptionComponent.vue';
import OfferSchedulePrecisionComponent from '../../../../Form/OfferSchedulePrecisionComponent.vue';

export default {
    name: 'UpdateWorkProviderPaidOfferCard',

    components: {
        FormError,
        WorkDaysComponent,
        WorkTimesComponent,
        ClassificationComponent,
        SalariesComponent,
        BenefitsComponent,
        OfferDescriptionComponent,
        OfferSchedulePrecisionComponent
    },

    props: {
        offer: {
            type: Object,
            default() {
                return {}
            }
        },


        trigger_update_offer: {
            type: Boolean,
            default: false,
        }
    },

    watch: {
        trigger_update_offer: function() {
            const self = this;
            if(self.trigger_update_offer) {
                self.update_offer();
            }
        }
    },

    data() {
        return {
            data: {
                name: '',
                description: '',
                schedule_precision: '',

                classification: {
                    interests: [],
                    experienceLevels: [],
                    all: null,
                    query: ''
                },

                workDays: [],
                workTimes: [],
                hoursPerWeek: '',
                calculated_hours_per_week: 0,
                schedule_type: '',
                workLocation: 'regular',
                reserved_for_students: false,

                salary: '',

                startDate: null,
                endDate: null,

                benefits: {
                    choices: [],
                    other: ''
                },
            },

            schedule_type_choices: ['exact', 'flexible'],

            locations: [],

            validation: {
                name: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                classification: {
                    interests: {
                        error: false,
                        key: 'errors.validation.fieldMandatory'
                    },

                    experienceLevels: {
                        error: false,
                        key: 'errors.validation.fieldMandatory'
                    }
                    
                },

                workDays: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                startDate: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                endDate: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                salaries: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                hoursPerWeek: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                schedule_type: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                workTimes: {
                    monday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    tuesday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    wednesday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    thursday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    friday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    saturday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    sunday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    }
                },

                workLocation: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                hybrid: {
                    days: {
                        number: {
                            error: false,
                            key: 'errors.validation.fieldMandatory'
                        }
                    }
                },

                locations: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                }
            },
            
            sectorOptions: [],
            classOptions: [],
            jobOptions: [],

            hasEndDate: false,
            workLocationOptions: ['remote', 'regular'],

            formValidated: false,
            loaded: false
        }
    },

    computed: {
        ...mapGetters([
            'currentProfile',
            'currentProfileType',
            'currentLanguage'
        ])
    },

    methods: {
        update_offer: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated) {
                if(self.data.schedule_type === 'exact') {
                    self.data.hoursPerWeek = self.data.calculated_hours_per_week;
                }

                if(self.data.hours_per_week_persons_choice === true) {
                    self.data.hoursPerWeek = '0';
                }
                
                if(!self.hasEndDate) {
                    self.data.endDate = null;
                }

                self.$axios
                    .put('/work-provider/offer/paid', { ...self.data, managementPosition: false, availableOnCall: false })
                    .then(response => {
                        if(response.status == 200){
                            self.$emit('update_offer_triggered', true);
                            self.$toasted.success(
                                self.$t('notifications.workProviderOfferUpdateSuccess'),
                                { icon: 'circle-check' }
                            );
                        }
                    })
                    .catch(error => {
                        self.$emit('update_offer_triggered', false);
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                    });
            } else {
                self.$emit('update_offer_triggered', false);
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        validate_mandatory_fields: function() {
            const self = this;
            self.resetValidation();

            ['name', 'workLocation', 'startDate', 'schedule_type'].forEach(key => {
                if(!self.data[key] || self.data[key] === '')
                    self.validation[key].error = true;
            });

            if(self.data.schedule_type === 'flexible') {
                if(!self.data.hours_per_week_persons_choice) {
                    if(!self.data.hoursPerWeek || self.data.hoursPerWeek === '' || parseFloat(self.data.hoursPerWeek) === 0) {
                        self.validation.hoursPerWeek.error = true;
                        self.formValidated = false;
                    }else if(parseFloat(self.data.hoursPerWeek) > self.data.calculated_hours_per_week) {
                        self.validation.hoursPerWeek.error = true;
                        self.validation.hoursPerWeek.key = 'errors.validation.hours_per_week_too_high_employer';
                        self.formValidated = false;
                    }
                }
            }
            
            ['workDays'].forEach(key => {
                if(!self.data[key] || self.data[key].length === 0) {
                    self.validation[key].error = true;
                }
            });

            ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
                if(self.data.workDays.includes(day)) {
                    const slots = ['start', 'end']
                    const period = self.data.workTimes.find(time => time.day === day)
                    slots.forEach(slot => {
                        if(period.timeSlots[0][slot].value === '' || !self.$utils._validate_time_slot(period.timeSlots[0][slot].value)) {
                            self.validation.workTimes[day][slot].error = true
                            self.validation.workTimes[day][slot].key = `validation.timeFormatInvalid.${slot}`
                        }
                    })
                }
            });

            if(self.data.classification.interests.length === 0) {
                self.validation.classification.interests.error = true;
                self.formValidated = false;
            }

            ['experienceLevels'].forEach(key => {
                if(self.data.classification[key].length === 0) {
                    self.validation.classification[key].error = true;
                    self.formValidated = false;
                }
            });

            self.data.salaries.forEach(salary => {
                if(salary.amount === '0' || salary.amount === '') {
                    self.validation.salaries.error = true;
                }

                if(salary.type && salary.amount && salary.type === 'hourly' && !self.$utils._validate_hourly_salary(salary.amount)) {
                    self.validation.salaries.error = true;
                    self.validation.salaries.key = 'errors.validation.salaryTooLow';
                }
            })
            
            if(self.hasEndDate && !self.data.endDate) {
                self.validation.endDate.error = true;
            }

            if(self.data.hybrid.hybrid) {
                if(!self.data.hybrid.days.number) {
                    self.validation.hybrid.days.number.error = true;
                    self.formValidated = false;
                }
            }

            const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false

                if(val === 'workTimes') {
                    days.forEach(day => {
                        if(self.validation[val][day]['start'].error || self.validation[val][day]['end'].error)
                            self.formValidated = false
                    })
                }
            });
        },

        resetValidation: function() {
            const self = this;

            self.formValidated = true;

            Object.keys(self.validation).forEach(val => {
                if(val === 'workTimes') {
                    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                    days.forEach(day => {
                        self.validation.workTimes[day].start.error = false;
                        self.validation.workTimes[day].end.error = false;
                    })
                } else if (val === 'classification') {
                    ['interests', 'experienceLevels'].forEach(key => {
                        self.validation.classification[key].error = false;
                    })
                } else {
                    self.validation[val].error = false
                }
            });

            self.validation.hybrid.days.number.error = false;
            self.validation.salaries.key = 'errors.validation.fieldMandatory';
            self.validation.hoursPerWeek.key = 'errors.validation.fieldMandatory';
        },

        removeWorkDay: function(day) {
            const self = this;

            self.data.workTimes.splice(self.data.workTimes.map(e => e.day).indexOf(day), 1);
            self.data.workDays.splice(self.data.workDays.indexOf(day), 1);
        },

        selectLocation: function(id) {
            const self = this;

            if(self.data.locations.includes(id)) {
                if(self.data.locations.indexOf(id) !== -1)
                    self.data.locations.splice(self.data.locations.indexOf(id), 1);
            }else {
                self.data.locations.push(id)
            }
        },

        update: function(data) {
            const self = this;
            Object.keys(data).forEach(key => {
                if(key === 'validation') { self.validation = data[key] }
                else { self.data[key] = data[key] }
            });
        },

        translate_schedule_type: function(key) {
            const self = this;
            return self.$t(`workProviderOffer.shared.card.schedule_type.choices.${key}`)
        },

        getLocations: function() {
            const self = this;
            
            self.$axios
                .get(`locations`)
                .then(response => {
                    if(response.status == 200){
                        self.locations = response.data.locations;
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    },

    mounted() {
        const self = this;

        self.getLocations();
        if(self.offer) {
            self.data = self.offer;

            if(self.data.endDate) {
                self.hasEndDate = true;
            }

            if(self.data.description === 'N/A') {
                self.data.description = '';
            }
            
            if(self.data.schedule_precision === 'N/A') {
                self.data.schedule_precision = '';
            }

            self.$nextTick(() => {
                self.loaded = true;
            })
        }
    }
}
</script>

